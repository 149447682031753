import React, { useContext } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AgentAnalytics from "./AgentAnalytics/AgentAnalytics";
import AgentQA from "./AgentQA/AgentQA";
import TicketQA from "./TicketQA/TicketQA";
import { TicketContext } from "../../Context/TicketContext";

const PerformanceModal = ({ analysisData }) => {
  const {
    showPerformanceModal,
    handleClosePerformanceModal,
    activeTabPerformanceModal,
    handleTabSelectPerformanceModal,
    notificationTicketQA,
  } = useContext(TicketContext);

  return (
    <Modal
      show={showPerformanceModal}
      onHide={handleClosePerformanceModal}
      // fullscreen={true}
      size="xl"
      centered
    >
      <Modal.Header closeButton className="p-1 px-2 border border-0">
        <Modal.Title>
          <h5 style={{ fontSize: "16px" }}>
            {" "}
            {notificationTicketQA ? "Ticket QA" : "Agent Performance"}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!notificationTicketQA && (
          <Row>
            <Col md={12}>
              <ul className="nav nav-underline nav-fill">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTabPerformanceModal === "Analytics" ? "active" : ""
                    } p-0`}
                    onClick={() => handleTabSelectPerformanceModal("Analytics")}
                  >
                    Analytics
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTabPerformanceModal === "AgentQA" ? "active" : ""
                    } p-0`}
                    onClick={() => handleTabSelectPerformanceModal("AgentQA")}
                  >
                    Agent QA
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTabPerformanceModal === "TicketQA" ? "active" : ""
                    } p-0`}
                    onClick={() => handleTabSelectPerformanceModal("TicketQA")}
                  >
                    Ticket QA
                  </Link>
                </li>
              </ul>
              <hr className="m-0 p-0 mb-3" />
            </Col>
          </Row>
        )}

        {activeTabPerformanceModal === "Analytics" && (
          <AgentAnalytics analysisData={analysisData} />
        )}
        {activeTabPerformanceModal === "AgentQA" && <AgentQA />}
        {activeTabPerformanceModal === "TicketQA" && <TicketQA />}
      </Modal.Body>
    </Modal>
  );
};

export default PerformanceModal;
