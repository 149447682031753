// utils/colorMapping.js

const colorCache = {};

export const generateDynamicColor = (index) => {
  const hue = (index * 137.508) % 360; // Use golden angle approximation for better color distribution
  return `hsl(${hue}, 70%, 80%)`;
};

export const getKpiColorMapping = (topCxQaScores) => {
  let colorIndex = 0;

  Object.entries(topCxQaScores).forEach(([category, metrics]) => {
    if (!colorCache[category]) {
      colorCache[category] = generateDynamicColor(colorIndex++);
    }

    Object.keys(metrics).forEach((metric) => {
      const key = `${category}_${metric}`;
      if (!colorCache[key]) {
        colorCache[key] = generateDynamicColor(colorIndex++);
      }
    });
  });

  return colorCache;
};
