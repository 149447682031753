import React, { useState, useEffect } from "react";
import { Accordion, ListGroup, Container, Card, Form } from "react-bootstrap";
import { getKpiColorMapping } from "../Helper/ColorMapping";

export default function QaScoreAccordion({ topCxQaScores, onKpiSelect }) {
  const [selectedKpis, setSelectedKpis] = useState({});
  const [colorMapping, setColorMapping] = useState({});

  useEffect(() => {
    if (topCxQaScores) {
      const colors = getKpiColorMapping(topCxQaScores);
      setColorMapping(colors);
    }
  }, [topCxQaScores]);

  const handleCategoryChange = (category, isChecked) => {
    const updatedKpis = { ...selectedKpis, [category]: isChecked };

    Object.keys(topCxQaScores[category]).forEach((metric) => {
      updatedKpis[`${category}_${metric}`] = isChecked;
    });

    setSelectedKpis(updatedKpis);
    onKpiSelect(updatedKpis);
  };

  const handleMetricChange = (category, metric, isChecked) => {
    const updatedKpis = {
      ...selectedKpis,
      [`${category}_${metric}`]: isChecked,
    };

    const allSelected = Object.keys(topCxQaScores[category]).every(
      (m) => updatedKpis[`${category}_${m}`]
    );

    updatedKpis[category] = allSelected;
    setSelectedKpis(updatedKpis);
    onKpiSelect(updatedKpis);
  };

  if (!topCxQaScores) {
    return (
      <Card className="border border-0 shadow fw-bold">
        <Card.Header
          className="p-2 bg-light border border-0"
          style={{ fontSize: "12px" }}
        >
          KPIs Scores
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="border border-0 shadow fw-bold">
      <Card.Header
        className="p-2 bg-light border border-0"
        style={{ fontSize: "12px" }}
      >
        KPIs Scores
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        <Container
          style={{ height: "100%", maxHeight: "17rem", overflowY: "auto" }}
        >
          <Accordion>
            {Object.entries(topCxQaScores).map(([category, metrics], index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        checked={!!selectedKpis[category]}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleCategoryChange(category, e.target.checked)
                        }
                        className="me-2"
                      />
                      <span className="fw-bold mx-2">
                        {category.charAt(0).toUpperCase() +
                          category.slice(1).replace(/_/g, " ")}
                      </span>
                    </div>
                    <span className="me-2">
                      {(
                        Object.values(metrics).reduce(
                          (acc, metric) => acc + metric.score,
                          0
                        ) / Object.values(metrics).length
                      ).toFixed(1)}
                      /10
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ListGroup variant="flush" className="border-0">
                    {Object.entries(metrics).map(([metric, details]) => (
                      <ListGroup.Item
                        key={metric}
                        className="d-flex justify-content-between align-items-center py-2 px-2"
                        style={{
                          borderBottom: "1px solid #ddd",
                          backgroundColor: "transparent",
                          transition: "background-color 0.2s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f8f9fa")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "transparent")
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="checkbox"
                            checked={!!selectedKpis[`${category}_${metric}`]}
                            onChange={(e) =>
                              handleMetricChange(
                                category,
                                metric,
                                e.target.checked
                              )
                            }
                            className="me-3"
                          />
                          <span
                            style={{
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            {metric.charAt(0).toUpperCase() + metric.slice(1)}
                          </span>
                          {!!selectedKpis[`${category}_${metric}`] && (
                            <div
                              className="my-auto"
                              style={{
                                backgroundColor:
                                  colorMapping[`${category}_${metric}`],
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                marginLeft: "10px",
                              }}
                            />
                          )}
                        </div>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "400",
                            color: "#666",
                          }}
                        >
                          {details.score}/10
                        </span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Container>
      </Card.Body>
    </Card>
  );
}
