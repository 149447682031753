import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useZafClient } from "../../utils/zafClient";
import axios from "axios";
import { ApiConfig } from "../../Config/ApiConfig";
import Markdown from "../Helper/MarkdownRenderer";
import { FaCheck, FaRegClipboard } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Loader from "../Loader/Loader";
import { TicketContext } from "../../Context/TicketContext";

const SummaryModal = () => {
  const { showSummaryModal, handleCloseSummaryModal } =
    useContext(TicketContext);

  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const client = useZafClient();

  useEffect(() => {
    const fetchSummary = async () => {
      if (showSummaryModal) {
        setLoading(true);

        try {
          // Fetch the current ticket ID
          const contextData = await client?.context?.();
          const ticketData = await client?.get?.(["ticket"]);
          const location = contextData?.location || "Unknown Location";

          const summaryData = {
            subdomain:
              location !== "modal"
                ? contextData?.account?.subdomain
                : localStorage.getItem("subdomain"),
            ticketId:
              location !== "modal"
                ? contextData?.ticketId
                : localStorage.getItem("ticketId"),
            ticketStatus:
              location !== "modal"
                ? ticketData?.ticket?.status
                : localStorage.getItem("ticketStatus"),
            tags:
              location !== "modal"
                ? ticketData?.ticket?.tags
                : JSON.parse(localStorage.getItem("tags")),
            comments:
              location !== "modal"
                ? ticketData?.ticket?.comments
                : JSON.parse(localStorage.getItem("comments")),
            assignee_id:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.user?.id
                : localStorage.getItem("assigneeId"),
            requester_id:
              location !== "modal"
                ? ticketData?.ticket?.requester?.id
                : localStorage.getItem("requesterId"),
            groupName:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.name
                : localStorage.getItem("groupName"),
            groupId:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.id
                : localStorage.getItem("groupId"),
            feature_name: "summaryx",
          };

          const config = {
            headers: { "Access-Control-Allow-Origin": "*" },
            withCredentials: true,
          };

          const response = await axios.post(
            ApiConfig.getSummary,
            summaryData,
            config
          );

          setSummary(response?.data?.ticket_summary);
        } catch (error) {
          console.error("Error fetching summary:", error);
          setSummary("Failed to fetch summary. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSummary();
  }, [showSummaryModal, client]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <Modal
      show={showSummaryModal}
      onHide={handleCloseSummaryModal}
      centered
      dialogClassName="classic-modal"
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#f8f9fa",
          borderBottom: "2px solid #dee2e6",
        }}
      >
        <Modal.Title
          style={{
            fontFamily: "'Times New Roman', Times, serif",
            fontWeight: "bold",
          }}
        >
          Ticket Summary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex justify-content-center align-items-center"
        style={{
          minHeight: "150px",
          backgroundColor: "#f8f9fa",
          padding: "6px 12px",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                data-tooltip-id="copy-summary"
                type="button"
                className="btn btn-link text-black grow-icon p-0"
                style={{
                  fontSize: "1rem",
                }}
                onClick={() => copyToClipboard(summary)}
              >
                {copied ? <FaCheck /> : <FaRegClipboard />}
              </button>
            </div>
            <blockquote
              style={{
                fontFamily: "'Georgia', serif",
                fontStyle: "italic",
                fontSize: "16px",
                lineHeight: "1.6",
                color: "#495057",
                marginBottom: "0",
                borderLeft: "4px solid #adb5bd",
                paddingLeft: "15px",
              }}
            >
              <Markdown markdown={summary} />
            </blockquote>

            <ReactTooltip
              id="copy-summary"
              place="top"
              content={`Click to copy the summary in clipboard`}
              style={{ zIndex: "9999" }}
              delayShow={800}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SummaryModal;
