import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
);

const BarLineChart = ({ actualData, targetCount }) => {
  if (!actualData || !targetCount) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Bar Line Chart</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  // Extract dates and actual ticket counts
  const dates = Object.keys(actualData);
  const actualCounts = Object.values(actualData);

  // Create an array for target count to match the length of actual data
  const targetCounts = new Array(dates.length).fill(targetCount);

  const data = {
    labels: dates, // Use dates from actualData as X-axis labels
    datasets: [
      {
        type: "bar",
        label: "Actual Ticket Count",
        data: actualCounts, // Use actualCounts for bar data
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        type: "line",
        label: "Target Ticket Count",
        data: targetCounts, // Use targetCounts for line data
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.4,
        pointBackgroundColor: "rgba(255, 99, 132, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(255, 99, 132, 1)",
        borderDash: [2, 2], // This makes the line dashed
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: Math.max(targetCount, ...actualCounts) + 2, // Dynamic max based on data
        title: {
          display: true,
          text: "Ticket Count",
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Bar Line Chart</h6>
      </Card.Header>
      <Card.Body>
        <Bar data={data} options={options} className="w-100 h-100" />
      </Card.Body>
    </Card>
  );
};

export default BarLineChart;
