import React from "react";
import { Card } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function HighlightedKPIs({ kpiData, overall }) {
  if (!kpiData || Object.keys(kpiData).length === 0) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Highlighted KPIs</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  // Function to get color based on score and target
  const getPathColor = (scored, target) => {
    if (scored > target) return "#3ddb84"; // Green if score > target
    if (scored === target) return "#ffb648"; // Yellow if score == target
    return "#ff6f61"; // Red if score < target
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Highlighted KPIs</h6>
      </Card.Header>
      <Card.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            flexWrap: overall ? "wrap" : "nowrap",
            overflowX: overall ? "visible" : "auto",
            padding: "10px",
          }}
        >
          {Object.entries(kpiData)
            .sort(([, a], [, b]) => (a?.scored ?? 0) - (b?.scored ?? 0)) // Sort in ascending order (for bottom 6)
            .slice(0, 6)
            .map(([kpiName, kpiDetails], index) => {
              const scored = kpiDetails?.scored ?? 0; // Default to 0 if scored is not available
              const target = kpiDetails?.target ?? 0; // Default to 0 if target is not available

              // Get the path color based on the comparison of scored and target
              const primaryColor = getPathColor(scored, target);
              const secondaryColor = `${primaryColor}88`; // Same color with 50% opacity

              return (
                <div
                  key={index}
                  style={{
                    textAlign: "center",
                    marginRight: "20px",
                    marginBottom: overall ? "20px" : "0",
                  }}
                >
                  <div style={{ height: "100px", width: "100px" }}>
                    <CircularProgressbarWithChildren
                      className="h-100 w-100"
                      value={scored}
                      text={`${scored.toFixed(1)}%`}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        textSize: "20px",
                        pathTransitionDuration: 0.5,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        pathColor: primaryColor, // Dynamically set path color
                      })}
                    >
                      <div style={{ width: "84%" }}>
                        <CircularProgressbar
                          className="h-100 w-100"
                          value={target}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            textSize: "20px",
                            pathTransitionDuration: 0.5,
                            textColor: "#000",
                            trailColor: "transparent",
                            pathColor: secondaryColor, // 50% opacity of the primary color
                          })}
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <p
                    className="text-muted m-0 p-0 mt-1 fw-semibold"
                    style={{ fontSize: "16px" }}
                  >
                    {kpiName}
                  </p>
                </div>
              );
            })}
        </div>
      </Card.Body>
    </Card>
  );
}
