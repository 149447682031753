import React from "react";
import "./Pages.css";
export default function ErrorPage() {
  return (
    <div className="access-denied-wrapper">
      <div className="lock"></div>
      <div className="message text-center" style={{ marginTop: "20px" }}>
        <h5>Access denied. Please contact the administrator for assistance.</h5>
      </div>
    </div>
  );
}
