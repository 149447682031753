import React from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "react-bootstrap";

const HeatMap = ({ heatMapData }) => {
  if (!heatMapData) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Heat Map</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }
  const dates = Object.keys(heatMapData);
  const sampleDate = dates[0]; // Use the first date as a sample for categories
  const categories = sampleDate ? Object.keys(heatMapData[sampleDate]) : [];

  // Prepare data in [y (date), x (category), value] format for heatmap
  const data = [];
  dates.reverse().forEach((date, i) => {
    categories.forEach((category, j) => {
      const value = heatMapData[date][category] || 0;
      if (value !== 0) {
        data.push([j, i, value]); // Only push non-zero values
      }
    });
  });

  // Heatmap option configuration
  const option = {
    tooltip: {
      position: "top",
      formatter: function (params) {
        return `Date: ${dates[params.data[1]]}<br/>
                Category: ${categories[params.data[0]]}<br/>
                Value: ${params.data[2].toFixed(1) ?? "No data"}%`; // Add '%' sign to display
      },
    },
    grid: {
      top: "2%",
      bottom: "2%",
      right: "0%",
      left: "2%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: categories,
      position: "top",
      splitArea: {
        show: true,
      },
      axisLabel: {
        interval: 0, // Show all labels
        formatter: function (value) {
          return value.split("_").join("\n"); // Split label text into two lines
        },
        fontSize: 10,
      },
    },
    yAxis: {
      type: "category",
      data: dates, // Dates and "Category_Averages" on the y-axis
      splitArea: {
        show: true,
      },
      axisLabel: {
        interval: 0, // Show all labels
        formatter: function (value) {
          return value.split("_").join("\n"); // Split label text into two lines
        },
        fontSize: 10,
      },
    },
    visualMap: {
      show: false,
      min: 1,
      max: 100,
      inRange: {
        color: ["rgb(240,99,63,1)", "rgb(222,203,14,1)", "rgb(139,172,29,1)"],
      },
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    series: [
      {
        name: "HeatMap",
        type: "heatmap",
        data: data,
        label: {
          show: true,
          formatter: function (params) {
            return `${params.value[2].toFixed(0)}%`;
          },
          fontSize: 10, // Reduce font size to fit the label within cell
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Heat Map</h6>
      </Card.Header>
      <Card.Body>
        <ReactECharts
          option={option}
          style={{ height: "400px", width: "100%" }}
        />
      </Card.Body>
    </Card>
  );
};

export default HeatMap;
