import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { FaUser } from "react-icons/fa"; // Import user icon from react-icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobe,
  faMobileAlt,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import {
  BsEmojiExpressionless,
  BsEmojiAngry,
  BsEmojiAstonished,
  BsEmojiSmile,
  BsEmojiGrin,
} from "react-icons/bs";
import { RiQuestionLine } from "react-icons/ri";
import { getKpiColorMapping } from "../Helper/ColorMapping";

function TicketComments({ ticketData, ticketId, selectedKpis }) {
  const sentimentMapping = {
    "Very Positive": <BsEmojiGrin className="text-success fs-5" />,
    Positive: <BsEmojiSmile className="text-success-emphasis fs-5" />,
    Neutral: <BsEmojiExpressionless className="text-warning fs-5" />,
    Negative: <BsEmojiAstonished className="text-danger-emphasis fs-5" />,
    "Very Negative": <BsEmojiAngry className="text-danger fs-5" />,
  };

  const defaultIcon = <RiQuestionLine className="fs-5" />;

  const commentsEndRef = useRef(null); // Create a reference to the end of the comments list
  const [colorMapping, setColorMapping] = useState({});

  useEffect(() => {
    if (ticketData) {
      const colors = getKpiColorMapping(
        ticketData?.conversations?.topcx_qa_scores || {}
      );
      setColorMapping(colors);
    }
  }, [ticketData]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when component mounts or comments change
  }, [ticketData]);

  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!ticketData || !ticketId) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header
          className="p-2 bg-light border border-0 fw-bold"
          style={{ fontSize: "12px" }}
        >
          <h6 className="mb-0">Ticket Comments</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  const customerSentiment =
    sentimentMapping[ticketData.ticket_customer_sentiment] || defaultIcon;
  const agentEmpathy =
    sentimentMapping[ticketData.ticket_agent_empathy] || defaultIcon;

  const comments = ticketData?.conversations?.ticket_comments || [];
  const userId = comments[0]?.author_id;

  const getChannelDetails = (channel) => {
    switch (channel) {
      case "email":
        return {
          icon: faEnvelope,
          text: "EMAIL",
        };
      case "web":
        return {
          icon: faGlobe,
          text: "WEB",
        };
      case "mobile":
        return {
          icon: faMobileAlt,
          text: "MOBILE",
        };
      case "chat":
        return {
          icon: faComments,
          text: "CHAT",
        };
      default:
        return {
          icon: faGlobe,
          text: channel.toUpperCase(),
        };
    }
  };

  const sanitizeText = (text) => {
    return text
      .replace(/<[^>]+>/g, "") // Remove HTML tags
      .replace(/\s+/g, " ") // Normalize whitespace
      .trim() // Remove leading/trailing whitespace
      .toLowerCase(); // Convert to lowercase for consistent comparison
  };

  const sentencesMap = {};
  Object.entries(selectedKpis).forEach(([key, selected]) => {
    if (selected) {
      const [category, metric] = key.split("_");
      const sentences =
        ticketData.conversations.topcx_qa_scores[category]?.[metric]
          ?.sentences || [];

      sentences.forEach((sentence) => {
        const sanitizedSentence = sanitizeText(sentence);
        if (!sentencesMap[sanitizedSentence]) {
          sentencesMap[sanitizedSentence] = colorMapping[key];
        }
      });
    }
  });

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <div className="d-flex justify-content-between">
          {/* Customer Sentiment */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Customer Sentiment
            </span>
            <span style={{ fontSize: "12px" }}>{customerSentiment}</span>
          </div>

          {/* Agent Empathy */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Agent Empathy
            </span>
            <span style={{ fontSize: "12px" }}>{agentEmpathy}</span>
          </div>

          {/* Ticket Time */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Time to Solve
            </span>
            <span style={{ fontSize: "12px" }}>
              {ticketData?.ticket_time || defaultIcon}
            </span>
          </div>

          {/* Ticket Comments Length */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Comments
            </span>
            <span style={{ fontSize: "12px" }}>
              {ticketData?.ticket_comments_length || defaultIcon}
            </span>
          </div>

          {/* Ticket Feedback */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Feedback
            </span>
            <span style={{ fontSize: "12px" }}>
              {ticketData?.ticket_feedback || defaultIcon}
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Container
          style={{ height: "100%", maxHeight: "34rem", overflowY: "auto" }}
        >
          {comments.map((comment, index) => {
            const channelDetails = getChannelDetails(comment.via.channel);
            const isUserComment = comment.author_id === userId;

            return (
              <Row
                key={index}
                className={`my-3 d-flex ${
                  isUserComment
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                <Col xs="auto">
                  <div
                    className={`d-flex align-items-center ${
                      isUserComment ? "" : "flex-row-reverse text-end"
                    }`}
                  >
                    <div>
                      <div
                        className={`d-flex align-items-center ${
                          isUserComment ? "" : "justify-content-end"
                        }`}
                        style={{ fontSize: "14px" }}
                      >
                        <FaUser size={10} className="me-2" />
                        <strong>{comment.author_name}</strong>
                        <span className={`ms-2 badge text-bg-light`}>
                          <FontAwesomeIcon
                            icon={channelDetails.icon}
                            className="me-1"
                          />
                          <span className="my-auto">{channelDetails.text}</span>
                        </span>
                      </div>
                      <div
                        className={`text-muted ${
                          isUserComment ? "" : "text-end"
                        }`}
                        style={{ fontSize: "10px" }}
                      >
                        {new Date(comment.created_at).toLocaleString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`p-2 mt-2 ${
                      isUserComment ? "text-black" : "text-white"
                    }`}
                    style={{
                      borderRadius: "10px",
                      maxWidth: "550px",
                      fontSize: "14px",
                      backgroundColor: isUserComment ? "#eeeeee99" : "#3156d3",
                    }}
                  >
                    {comment.html_body
                      .split(/([.!?]\s+|\n)/g)
                      .map((sentence, i) => {
                        const sanitizedSentence = sanitizeText(sentence);
                        const highlightColor =
                          sentencesMap[sanitizedSentence] || "";

                        return (
                          <span
                            key={i}
                            style={{ backgroundColor: highlightColor }}
                          >
                            {sentence}
                          </span>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            );
          })}
          <div ref={commentsEndRef} />
        </Container>
      </Card.Body>
      <Card.Footer className="bg-light p-2  border border-0">
        <span className="ms-2 fw-bold">#&nbsp;{ticketId || "*****"}</span>
      </Card.Footer>
    </Card>
  );
}

export default TicketComments;
