import React, { useState } from "react";

import { ProgressBar, Tab, Tabs, Card } from "react-bootstrap";

export default function ProgressTracker({ progressData }) {
  const [activeTab, setActiveTab] = useState("all");

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  if (!progressData || Object.keys(progressData).length === 0) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Progress Tracker</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  // Function to return a progress bar color based on status
  const getProgressBarVariant = (status) => {
    switch (status) {
      case "On-Track":
        return "#007bff"; // Blue
      case "Ahead":
        return "#28a745"; // Green
      case "Behind":
        return "#dc3545"; // Red
      default:
        return "#6c757d"; // Gray
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "On-Track":
        return { backgroundColor: "#e0f7fa", color: "#007bff" }; // Light blue background with blue text
      case "Ahead":
        return { backgroundColor: "#e8f5e9", color: "#28a745" }; // Light green background with green text
      case "Behind":
        return { backgroundColor: "#fce4ec", color: "#dc3545" }; // Light red background with red text
      default:
        return { backgroundColor: "#e0e0e0", color: "#6c757d" }; // Light gray background with gray text
    }
  };

  const CustomBadge = ({ status }) => {
    const style = getStatusStyle(status);
    return (
      <div
        style={{
          backgroundColor: style.backgroundColor,
          color: style.color,
          padding: "2px 4px",
          borderRadius: "8px", // Rounded corners
          fontSize: "10px",
          fontWeight: "bold",
          display: "inline-block", // Make it inline like a badge
          textAlign: "center",
          minWidth: "60px", // Ensure a minimum width for consistent look
        }}
      >
        {status}
      </div>
    );
  };

  // Function to filter progress data based on the active tab
  const filteredProgressData = progressData.filter((item) => {
    if (activeTab === "all") return true;
    return item.status === activeTab;
  });

  const renderProgressItem = (item, index) => (
    <div key={index} className="mb-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <strong style={{ fontSize: "14px" }}>
            {item?.subcategory}{" "}
            <span style={{ fontSize: "12px" }} className="ms-1 text-muted">
              (ticket count: {item?.ticket_count})
            </span>
          </strong>
        </div>
        <CustomBadge status={item?.status} />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <ProgressBar
          now={item?.score}
          style={{
            width: "85%",
            marginRight: "10px",
            height: "8px",
            backgroundColor: "#e0e0e0", // Light gray trail color
          }}
        >
          <div
            style={{
              backgroundColor: getProgressBarVariant(item?.status),
              height: "100%",
              width: `${item?.score}%`,
              borderRadius: "4px",
            }}
          />
        </ProgressBar>
        <strong style={{ fontSize: "12px" }}>{item?.score}%</strong>
      </div>
    </div>
  );

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Progress Tracker</h6>
      </Card.Header>
      <Card.Body>
        <Tabs id="progress-tabs" activeKey={activeTab} onSelect={handleSelect}>
          <Tab
            eventKey="all"
            title="All"
            className="border-bottom border-x p-3"
          >
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {filteredProgressData.map((item, index) =>
                renderProgressItem(item, index)
              )}
            </div>
          </Tab>
          <Tab
            eventKey="On-Track"
            title="On-Track"
            className="border-bottom border-x p-3"
          >
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {filteredProgressData.map((item, index) =>
                item.status === "On-Track"
                  ? renderProgressItem(item, index)
                  : null
              )}
            </div>
          </Tab>
          <Tab
            eventKey="Ahead"
            title="Ahead"
            className="border-bottom border-x p-3"
          >
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {filteredProgressData.map((item, index) =>
                item.status === "Ahead" ? renderProgressItem(item, index) : null
              )}
            </div>
          </Tab>
          <Tab
            eventKey="Behind"
            title="Behind"
            className="border-bottom border-x p-3"
          >
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {filteredProgressData.map((item, index) =>
                item.status === "Behind"
                  ? renderProgressItem(item, index)
                  : null
              )}
            </div>
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
}
