import React, { useState, useEffect } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function TicketReview({ ticketData }) {
  const [activeTab, setActiveTab] = useState("dignified");
  const [dignifiedTickets, setDignifiedTickets] = useState([]);
  const [neutralTickets, setNeutralTickets] = useState([]);
  const [criticalTickets, setCriticalTickets] = useState([]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // Function to format date and time
  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  // Function to determine the appropriate emoji based on sentiment
  const getSentimentEmoji = (sentiment, empathy) => {
    const sentimentEmoji = {
      Positive: "😊",
      Negative: "😞",
      Neutral: "😐",
    };

    const empathyEmoji = {
      High: "💖",
      Medium: "👍",
      Low: "😔",
    };

    return `${sentimentEmoji[sentiment]} ${empathyEmoji[empathy]}`;
  };

  useEffect(() => {
    const processTickets = (data) => {
      if (!data || !data.high_rated || !data.neutral || !data.critical) return;

      const highRated = (data.high_rated || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment: "Positive",
        agent_empathy: "High",
        reason: ticket.qa_classification_reasons?.high_rated || [],
        date: formatDateTime(ticket.solved_at),
      }));

      setDignifiedTickets(highRated);

      const neutral = (data.neutral || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment: "Neutral",
        agent_empathy: "Medium",
        reason: ticket.qa_classification_reasons?.neutral || [],
        date: formatDateTime(ticket.solved_at),
      }));
      setNeutralTickets(neutral);

      const critical = (data.critical || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment: "Negative",
        agent_empathy: "Low",
        reason: ticket.qa_classification_reasons?.critical || [],
        date: formatDateTime(ticket.solved_at),
      }));
      setCriticalTickets(critical);
    };

    if (ticketData) {
      processTickets(ticketData);
    }
  }, [ticketData]);

  // If no ticket data, show 'No Data Found' card
  if (!ticketData) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Ticket Review</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  const renderTableRows = (data = []) => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="4" className="text-center text-muted">
            <h5>No Data Found</h5>
          </td>
        </tr>
      );
    }
    return data.map((ticket, index) => (
      <tr key={index}>
        <td>#{ticket.ticket_number}</td>
        <td>
          {getSentimentEmoji(ticket.customer_sentiment, ticket.agent_empathy)}
        </td>
        <td>
          {ticket.reason.map((reason, idx) => (
            <Badge
              bg={
                activeTab === "dignified"
                  ? "success"
                  : activeTab === "neutral"
                  ? "secondary"
                  : "danger"
              }
              className="me-1 mb-1"
              key={idx}
              style={{ fontWeight: "bold" }}
            >
              {reason}
            </Badge>
          ))}
        </td>
        <td>{ticket.date}</td>
      </tr>
    ));
  };

  const renderTable = (data = []) => {
    return (
      <Table responsive>
        <thead className="bg-light">
          <tr>
            <th>Ticket Number</th>
            <th>Sentiment</th>
            <th>Reason</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{renderTableRows(data)}</tbody>
      </Table>
    );
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Ticket Review</h6>
      </Card.Header>
      <Card.Body>
        <ul className="nav nav-underline nav-fill">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "dignified" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("dignified")}
            >
              Dignified
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "neutral" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("neutral")}
            >
              Neutral
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "critical" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("critical")}
            >
              Critical
            </Link>
          </li>
        </ul>
        <hr className="m-0 p-0 mb-2" />

        {activeTab === "dignified" && renderTable(dignifiedTickets)}
        {activeTab === "neutral" && renderTable(neutralTickets)}
        {activeTab === "critical" && renderTable(criticalTickets)}
      </Card.Body>
    </Card>
  );
}
